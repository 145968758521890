<!--
 * @Author: 冯杰
 * @Date: 2021-08-09 16:38:37
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-09-01 17:04:44
 省市区联动
-->
<template>
  <div>
    <el-row>
      <el-col :span="8">
        <el-select v-model="sheng" placeholder="请选择省" @change="clickSheng">
          <el-option
            v-for="item in options1"
            :key="item.regionCode"
            :label="item.regionName"
            :value="item.regionCode"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="8">
        <el-select v-model="shi" placeholder="请选择市" @change="clickShi">
          <el-option
            v-for="item in options2"
            :key="item.regionCode"
            :label="item.regionName"
            :value="item.regionCode"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="8">
        <el-select v-model="qu" placeholder="请选择区" @change="clickQu">
          <el-option
            v-for="item in options3"
            :key="item.regionCode"
            :label="item.regionName"
            :value="item.regionCode"
          >
          </el-option>
        </el-select>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import request from '@/found/utils/request';

export default {
  data() {
    return {
      options1: [],
      options2: [],
      options3: [],
      sheng: '',
      shi: '',
      qu: '',
      clear: false,
    };
  },
  mounted() {
    this.getSheng();
  },
  methods: {
    getSheng() {
      request
        .post('/mdm/mdmRegionController/selectStartWithProvince', { regionLevel: '1' })
        .then((res) => {
          if (!res.success) return;
          this.options1 = res.result;
        });
    },

    clickSheng() {
      this.getShi();
      this.options1.forEach((item) => {
        if (item.regionCode === this.sheng) {
          this.$emit('cityData', item.regionName);
          this.$parent.$parent.$parent.cityData(item.regionName);
        }
      });
    },

    getShi() {
      if (this.sheng) {
        request
          .post('/mdm/mdmRegionController/selectStartWithProvince', { parentCode: this.sheng })
          .then((res) => {
            if (!res.success) return;
            this.options2 = res.result;
          });
      }
    },

    clickShi() {
      this.getQu();
      this.options2.forEach((item) => {
        if (item.regionCode === this.shi) {
          this.$emit('cityData', item.regionName);
          this.$parent.$parent.$parent.cityData(item.regionName);
        }
      });
    },

    getQu() {
      if (this.shi) {
        request
          .post('/mdm/mdmRegionController/selectStartWithProvince', { parentCode: this.shi })
          .then((res) => {
            if (!res.success) return;
            this.options3 = res.result;
          });
      }
    },
    clickQu() {
      this.options3.forEach((item) => {
        if (item.regionCode === this.qu) {
          this.$emit('cityData', item.regionName);
          this.$parent.$parent.$parent.cityData(item.regionName);
        }
      });
    },
  },
};
</script>
